@import '../theme-bootstrap';

$pr-histogram-height: 32px;

$pr-qna-default-border-width: 1px;

@mixin pr-button {
  @include button;
  background: $color-black;
  color: $color-white;
  font-size: 12px;
  height: 44px;
  line-height: 16px;
  margin: 24px auto 0;
  width: 343px;
  @include breakpoint($medium-up) {
    font-size: 12px;
  }
  &:hover {
    opacity: 0.7;
    background: $color-black;
    color: $color-pink-1;
  }
}

@mixin pr-body-text {
  @include breakpoint($medium-up) {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: normal;
  }
  @include font--proxima-nova;
  font-size: mobilePx(25px);
  line-height: mobilePx(29px);
}

@mixin pr-aside-text {
  @include font--proxima-nova;
  @include breakpoint($medium-up) {
    font-size: 18px;
  }
  font-size: mobilePx(25px);
}

@mixin pr-link-text {
  @include font--proxima-nova-bold;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: $color-dark-gray;
  @include breakpoint($medium-up) {
    font-size: 14px;
    line-height: 20px;
    order: 2;
    flex: 40%;
  }
  font-size: mobilePx(25px);
  line-height: mobilePx(28px);
  text-transform: uppercase;
}

// 5 hearts/histogram count
@mixin pr-histogram-text {
  @include eyebrow-small;
}

// Mixin function for review star Background image
@mixin pr-star-clipping($width, $height) {
  $pr-count: (0, 25, 50, 75, 100);
  // Add the clip values. Note we use clip because clip-path does not work in
  // IE. This unfortunately requires we use explicit px and not %.
  @each $pr-value in $pr-count {
    $clipped-width: $width * ($pr-value / 100);
    .pr-star-v4-#{$pr-value}-filled .pr-icon--fill,
    .pr-star-v4-#{$pr-value}-filled::after {
      clip: rect(0, $clipped-width, 100px, 0); // assume 100px is max height
    }
  }
}

@mixin pr-star-bg ($width, $height) {
  @include pr-star-clipping($width, $height);
  .pr-star-v4 {
    background: none;
    width: $width !important;
    height: $height !important;
    &::before,
    &::after {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      width: $width !important;
      height: $height !important;
    }
    &::before {
      background: url(#{$base-theme-path}/img/icons/svg/heart--grey.svg) no-repeat;
    }
    &::after {
      background: url(#{$base-theme-path}/img/icons/svg/heart--pink.svg) no-repeat;
    }
  }
}

@mixin pr-star-svg($width-outer, $width, $height) {
  @include pr-star-clipping($width, $height);
  .pr-star-v4 {
    background-image: none;
    height: $height;
    padding: 0;
    position: relative;
    width: $width-outer !important;
    .icon {
      fill: $color-pink-accent;
      height: $height;
      left: 0;
      position: absolute;
      top: 0;
      width: $width;
    }
    .icon.pr-icon--bg {
      fill: $color-gray;
    }
  }
}
// Common Section render for MPP, SPP, Review, Endeca search and Display Page
#pr-write,
#spp-reviews-display,
.product-grid__content,
.product-full__details,
.product-full__review-snippet,
#pr-reviewsnippet,
.search-wrapper__results,
.write_a_review__container {
  .p-w-r {
    font-family: $font--proxima-nova;
    margin: 0;
    // Review Helpful
    .pr-rd-star-rating {
      @include pr-star-bg(16px, 16px);
    }
    .pr-rd-no-reviews {
      padding: 25px 0;
      @include breakpoint($medium-up) {
        padding: 50px 0;
      }
      a {
        @include pr-button;
      }
    }
  }
}

// Apply stars to icon heart transformation to all stars
.p-w-r {
  a {
    text-decoration: none;
  }
  .pr-snippet,
  .pr-rd-star-rating,
  .pr-media-card-content-text-stars {
    .pr-snippet-stars {
      .pr-rating-stars {
        @include pr-star-svg(24px, 20px, 20px);
      }
    }
  }
}

// get rid of default focus/active
body .p-w-r :focus,
body .p-w-r a:focus {
  outline: none;
  box-shadow: none;
}

// default font color
body .p-w-r,
body .p-w-r .pr-review-display span {
  color: $color-black;
}

#spp-reviews-display {
  max-width: 1080px;
  margin: 0 auto;
  .p-w-r {
    // List of elements that need to be arbitrarily hidden across page
    .pr-review-snapshot-header,
    .pr-checkbox-icon,
    .pr-snippet-reco-to-friend-percent span[aria-hidden="true"],
    .pr-review-snapshot-histogram .pr-review-snapshot-block-headline,
    .pr-filter-btn__voteCount,
    .pr-review-snapshot-block-cons,
    .pr-rd-details .pr-rd-bold,
    .pr-badging-icon,
    .pr-review .pr-accordion,
    .pr-rd-bottomline,
    .pr-review-filter-headline,
    .pr-search-icon,
    .pr-rd-reviewer-type,
    .pr-review .pr-snippet-rating-decimal,
    .pr-rd-content-block::before,
    .pr-rd-content-block::after,
    .pr-rd-main-header::before,
    .pr-rd-pagination::after {
      display: none !important;
    }
    // General settings for flex containers
    .pr-review-snapshot-block-container,
    .pr-rd-main-header {
      align-items: center;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin: 0;
      @include breakpoint($large-up) {
        align-items: flex-start;
        flex-direction: row;
      }
    }
    // General histogram styles
      .pr-ratings-histogram {
        margin: 0 auto;
        max-width: 90%;
        &-bar {
          height: $pr-histogram-height;
        }
        &-barValue {
          background: $color-pink-accent;
          height: $pr-histogram-height;
        }
        &-list-item::after {
          content: '';
          display: block;
          height: 16px;
        }
        .pr-histogram-label,
        .pr-histogram-count {
          color: $color-black;
          font-size: 12px;
          font-weight: 700;
          padding: 0 18px;
          text-transform: uppercase;
          @include breakpoint($medium-up) {
            font-size: 14px;
          }
        }
      }
    // Top of reviews rows/columns, listed in order
    .pr-review-snapshot-block-container {
      margin: 16px 0;

      .pr-review-snapshot {
        &-block {
          border: none;
          margin: 0 auto;
        }
        &-snippets {
          margin-bottom: 32px;
          order: 1;
          width: 100%;
          @include breakpoint($large-up) {
            width: 33%;
            margin: 0 auto;
          }
          .pr-snippet-reco-to-friend {
            align-items: center;
            display: flex;
            font-weight: 700;
            justify-content: center;
            max-width: 100%;
            .pr-reco-value,
            .pr-reco-to-friend-message {
              color: $color-black;
              font-size: 14px;
              max-width: 100%;
              text-transform: uppercase;
              @include breakpoint($medium-up) {
                font-size: 16px;
              }
            }
            .pr-reco-value {
              @include breakpoint($large-up) {
                line-height: normal;
              }
            }
            &-message {
              max-width: 100%;
            }
          }
          .pr-snippet-stars {
            &,
            &-reco-inline,
            &-reco-stars .pr-snippet,
            &-reco-stars .pr-snippet-read-and-write {
              display: flex;
              flex-direction: column;
            }
            &-reco-stars {
              order: 2;
              margin-top: 6px;
              text-align: center;
              .pr-reco-value {
                font-size: 16px;
              }
              .pr-snippet-review-count {
                color: $color-black;
              }
              .pr-snippet-rating-decimal {
                border: none;
                color: $color-black;
                font-family: $font--bauer-bodoni;
                font-size: 32px;
                margin: 12px 0 0;
                padding: 0;
                @include breakpoint($medium-up) {
                  font-size: 48px;
                }
              }
              .pr-rating-stars {
                @include pr-star-bg(28px, 28px);
              }
              .pr-snippet-read-and-write {
                margin: 0;
                .pr-snippet-review-count {
                  span {
                    font-size: 14px !important;
                  }
                  &::before {
                    content: "(";
                  }
                  &::after {
                    content: ")";
                  }
                }
                .pr-snippet-write-review-link {
                  @include pr-button;
                  font-size: 12px;
                  max-width: 300px;
                  width: auto;
                }
              }
            }
          }
        }
        &-block-histogram,
        &-msq {
          width: 100%;
          @include breakpoint($large-up) {
            font-size: 14px;
            width: 33%;
          }
        }
        &-block-histogram {
          order: 2;
        }
        &-block {
          &-pros {
            margin-top: 16px;
            order: 3;
            width: 100%;
            @include breakpoint($large-up) {
              margin: 0;
              width: 33%;
            }
            dt {
              background: none;
              padding: 0;
            }
            .pr-snapshot-tag-def:first-of-type {
              margin: 12px 0 0;
            }
            .pr-snapshot-tag-def {
              height: auto;
              margin: 12px 0;
              padding: 0;
              text-align: center;
            }
            .pr-filter-btn--default {
              line-height: normal;
              padding: 0;
              text-align: center;
              span {
                color: $color-black;
                font-size: 18px;
                @include breakpoint($medium-up) {
                  font-size: 24px;
                }
              }
            }
          }
          &-headline {
            background: none;
            font-size: 14px;
            text-align: center;
            text-transform: uppercase;
          }
        }
      }
    }
    .pr-review-snapshot,
    .pr-review-display {
      padding: 16px;
    }
    .pr-review-display {
      .pr-rd-main-header {
        @include breakpoint($medium-up) {
          justify-content: space-between;
        }
        &::after {
          display: none;
        }
        &-search-sort,
        .pr-rd-main-header__utility-bar {
          background: none;
          display: flex;
          flex-direction: column-reverse;
          flex-wrap: wrap;
          margin-top: 12px;
          max-width: 100% !important;
          width: 100%;
          &::before {
            content: $pr-sort-copy;
            display: block;
            font-weight: 700;
            margin-top: 16px;
            order: 1;
            text-align: left;
            width: 100%;
            @include breakpoint($large-up) {
              margin: 0;
            }
          }
          @include breakpoint($medium-up) {
            align-items: flex-end;
            flex-direction: row;
            gap: 10px 16px;
            width: 420px;
          }
          @include breakpoint($large-up) {
            margin: 0;
            padding: 0;
            width: 450px;
          }
          .pr-rd-review-header-sorts {
            padding: 0;
            @include breakpoint($medium-up) {
              order: 2;
              width: 47%;
            }
            .pr-rd-sort {
              border-color: $color-black;
              color: $color-black;
              margin: 0;
            }
          }
          .pr-rd-sort {
            + span {
              display: none;
            }
          }
          .pr-rd-sort,
          .pr-rd-review-header__accordion-title {
            background-position: 92% 50%;
            height: 55px;
            max-width: 100%;
            padding: 0 16px;
            &,
            &-group {
              @include breakpoint($medium-up) {
                width: 100%;
              }
              optgroup {
                color: transparent;
              }
              option {
                color: $color-black;
              }
            }
          }
          .pr-rd-main-header-search,
          .pr-rd-main-header__search {
            height: auto;
            margin-top: 16px;
            padding: 0;
            width: 100%;
            @include breakpoint($medium-up) {
              margin: 0;
              order: 3;
              width: 47%;
            }
          }
          .pr-rd-search-reviews {
            &-input {
              input {
                padding: 0 16px 0 12px;
                background: $color-pink-2;
                border: none;
                height: 55px !important;
                &::placeholder {
                  font-size: 14px;
                  text-transform: capitalize;
                }
                &:focus {
                  box-shadow: none;
                }
              }
            }
            &-icon-button {
              background: url('#{$base-theme-path}svg-icons/src/search.svg') 50%/24px no-repeat;
              border: none;
              display: block;
              height: 55px;
            }
          }
        }
        &__utility-bar {
          max-width: 50%;
        }
        .pr-rd-review-header-contents {
          background: none;
          display: flex;
          flex-wrap: wrap;
          gap: 10px 16px;
          padding: 0;
          width: 100%;
          @include breakpoint($medium-up) {
            justify-content: space-between;
            width: 420px;
          }
          @include breakpoint($large-up) {
            justify-content: flex-start;
            width: auto;
          }
          &::before {
            content: $pr-filter-copy;
            font-weight: 700;
            text-align: left;
            width: 100%;
          }&::after {
            display: none;
          }
        }
        .pr-rd-review-header__accordion-title,
        .pr-multiselect-button {
          border: solid 1px $color-black;
          font-size: 14px;
          height: 55px;
          margin: 0;
          min-width: 0;
          overflow: hidden;
          padding: 0 8px 0 16px;
          width: 100%;
          .pr-caret-icon {
            margin: 0 6px;
            transition: .5s;
            svg {
              margin-top: -4px;
              polyline {
                stroke: $color-black;
                stroke-width: 5px;
              }
            }
          }
          &[aria-expanded="true"] {
            .pr-caret-icon {
              transform: rotateZ(90deg);
            }
          }
          &-item {
            align-items: center;
            display: flex;
          }
        }
        .pr-multiselect {
          width: 47%;
          @include breakpoint($medium-up) {
            width: auto;
          }
          &-count {
            background: $color-gold;
            border-radius: 50%;
            display: block !important;
            font-weight: 400;
            height: 18px;
            margin: 0;
            order: 1;
            padding: 1px 0;
            width: 18px;
          }
          &-item {
            display: flex;
            align-items: center;
            padding: 10px 16px;
            &:hover,
            &--highlight {
              background: $color-off-white;
            }
            input[type="checkbox"] {
              appearance: none;
              border: 1px solid $color-black;
              height: 16px !important;
              left: 16px;
              margin: 0;
              padding: 0;
              top: auto;
              width: 16px !important;
              &::before {
                background: black;
                clip-path: polygon(0 0,0 100%,50% 100%,42% 80%,23% 60%,25% 52%,42% 70%,77% 33%,82% 38%,42% 80%,50% 100%,100% 100%,100% 0);
                content: '';
                display: inline-block;
                height: 14px;
                opacity: 0;
                width: 14px;
              }
              &:focus {
                outline: none;
              }
              &:checked {
                &::before {
                  opacity: 1;
                  outline: none;
                }
              }
            }
            &-label {
              margin: 0 0 0 32px;
            }
          }
        }
        .pr-rd-review-total {
          font-size: 16px;
          margin: 0;
          order: 4;
          max-width: 420px;
          padding: 0;
          width: 100%;
          @include breakpoint($large-up) {
            margin-top: 24px;
            max-width: 100%;
            text-align: left;
          }
          + div {
            order: 2;
            width: 100%;
            @include breakpoint($medium-up) {
              max-width: 420px;
            }
            .pr-review-filter-clear-all {
              color: black;
            }
            .pr-filter-btn {
              &:hover,
              &:active {
                background: $color-pink-accent;
                .pr-cross-icon {
                  svg {
                    border: solid 1px $color-white;
                  }
                  .pr-cross-icon__circle {
                    fill: $color-pink-accent;
                  }
                  .pr-cross-icon__line {
                    stroke: $color-white;
                  }
                }
              }
              .pr-cross-icon {
                height: auto;
                width: auto;
                svg {
                  border: solid 1px $color-black;
                  border-radius: 50%;
                  display: block;
                  height: 16px;
                  width: 16px;
                }
                .pr-cross-icon__circle {
                  fill: transparent;
                }
                .pr-cross-icon__line {
                  stroke: $color-black;
                }
              }
            }
          }
          .pr-rd-review-header {
            width: 100%;
          }
        }
        .pr-rd-main-header-with-filters {
          padding-bottom: 24px;
        }
      }
      // Individual reviews
      .pr-review {
        border-bottom: solid 1px $color-grey;
        margin: 0;
        padding: 36px 0;
        &:first-of-type {
          border-top: solid 1px $color-grey;
          margin-top: 32px;
        }
        .pr-rd-images {
          display: flex;
          margin: 16px 0;
          figure {
            float: none;
          }
        }
        .pr-rd-helpful-action {
          &-group {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            gap: 0 16px;
            margin-top: 24px;
          }
          &-legend {
            @include breakpoint($med-small-down) {
              width: auto;
            }
          }
          .pr-rd-bold {
            color: $color-black;
          }
        }
        .pr-rd-star-rating {
          color: $color-black;
          float: none;
        }
        .pr-rd-review-headline {
          float: none;
          font-size: 24px;
          margin: 6px 0 0;
        }
        .pr-rd-description {
          margin-bottom: 24px;
          @include breakpoint($medium-up) {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0;
            width: 100%;
          }
          & p,
          & span {
            color: $color-black;
            font-size: 14px;
            @include breakpoint($medium-up) {
              font-size: 16px;
            }
          }
          &-text {
            max-width: 80%;
          }
          .pr-rd-side-content-block {
            order: 2;
            position: static;
            width: auto;
            @include breakpoint($medium-up) {
              margin-top: -68px;
              width: 100%;
              max-width: 200px;
            }
          }
          .pr-rd-details {
            text-transform: capitalize;
          }
          .pr-rd-author-submission-date {
            margin-bottom: 10px;
          }
          .pr-rd-author-nickname,
          .pr-rd-author-location {
            margin-bottom: 6px;
          }
          .pr-rd-author-nickname span {
            font-weight: 700;
          }
        }
        .pr-rd-footer {
          margin: 0;
          padding: 0;
          .pr-helpful-btn {
            background: none !important;
            border: none;
            border-radius: 0;
            box-shadow: 0 0 0 1px $color-black;
            padding: 2px 10px 6px;
            svg {
              height: 24px;
              margin: 0 4px 0 -11px;
              width: 37px;
            }
            &:active svg path,
            svg path {
              fill: $color-black;
            }
            &.pr-helpful-active {
              box-shadow: 0 0 0 2px $color-pink-accent;
              opacity: 1;
              span {
                color: $color-pink-accent;
              }
              svg path {
                fill: $color-pink-accent;
              }
            }
            .pr-helpful-count {
              min-width: 20px;
            }
            &.pr-helpful-yes {
              margin-right: 16px;
            }
          }
        }
        .pr-rd-main-footer,
        .pr-rd-flag-review-btn {
          color: $color-black;
          @include breakpoint($med-small-down) {
            margin: 0 24px;
          }
        }
      }
      // Review pagination
      .pr-rd-main-footer {
        padding: 24px 0;
        .pr-rd-content-block {
          @include breakpoint($medium-up) {
            display: flex;
            justify-content: space-between;
            width: 100%;
          }
        }
        .pr-rd-review-position {
          margin-bottom: 8px;
        }
        .pr-rd-to-top,
        .pr-rd-pagination-btn  {
          color: black;
          margin-bottom: 24px;
        }
        .pr-rd-pagination {
          border-top: none;
        }
      }
    }
    .pr-media-modal {
      .button__next,
      .button__prev {
        display: none;
      }
      .button__close {
        &,
        .pr-cross-icon {
          width: 40px;
          height: 40px;
          &__line,
          &__circle {
            stroke-width: 1.5;
          }
        }
        &:hover {
          .pr-cross-icon {
            &__line,
            &__circle {
              stroke: $color-white;
            }
          }
        }
      }
      .carousel__footer {
        &--flagging {
          .pr-rd-helpful-action {
            .pr-rd-helpful-text {
              color: $color-white;
            }
            .pr-helpful-btn {
              &:after {
                right: 4px;
              }
              &:last-child {
                width: 70px;
              }
              &:hover {
                &,
                .pr-helpful-count {
                  color: $color-white;
                }
              }
            }
          }
          .pr-rd-flag-image-container {
            a {
              color: $color-white;
            }
          }
        }
      }
    }
  }
}
// Accordion buttons
#spp-reviews-display .p-w-r .pr-accordion {
  .pr-accordion-btn {
    @include pr-link-text;
    color: $color-dark-gray;
    span {
      @include pr-link-text;
      color: $color-dark-gray;
    }
    .pr-caret-icon {
      @include transform(rotate(0deg));
      position: relative;
      transform-origin: 50% 50%;
      transition-delay: 0.1s;
      svg {
        display: none;
      }
      // arrow
      &:before {
        @include transform(translate(-50%, -50%));
        @include triangle(12px, $color-black, down);
        position: absolute;
        top: calc(50% + 3px);
        left: 50%;
        content: '';
        border-top-width: 10px; // make triangle less wide
      }
    }
  }
  // typo is from PW = exapanded
  &.pr-accordion-exapanded .pr-accordion-btn {
    .pr-caret-icon {
      @include transform(rotate(180deg));
    }
  }
}

// Dropdown
.selectBox-options.pr-rd-sort-selectBox-dropdown-menu {
  background: $color-pink-2;
  padding: 20px;
  max-height: none;
  border: 0;
  li.selectBox-optgroup {
    display: none;
  }
  li a {
    line-height: 25px;
    color: $color-dark-gray;
    font-size: 12px;
    @include breakpoint($medium-up) {
      font-size: 14px;
    }
  }
  li.selectBox-selected,
  li.selectBox-hover {
    a {
      color: $color-dark-gray;
      font-weight: bold;
      background: none;
    }
  }
}

// Power Reviews Header
.spp-reviews {
  &__header {
    background: $color-pink-2;
    text-align: center;
    padding: 0;
    margin-bottom: 1rem;
    .header-title {
      @include header-medium;
      padding: 1rem 0;
    }
    .tabs-nav {
      display: flex;
      justify-content: center;
      gap: 10px;
      border-bottom: $pr-qna-default-border-width solid $color-black;
      .tab-nav {
        cursor: pointer;
        @include header-small;
        margin: -#{$pr-qna-default-border-width};
        border: $pr-qna-default-border-width solid $color-black;
        padding: 16px 30px;
        @include breakpoint($medium-up) {
          padding: 16px 60px;
        }
        &.active {
          background-color: $color-white;
          border-bottom: $pr-qna-default-border-width solid $color-white;
        }
        &--single {
          cursor: default;
        }
      }
    }
  }
  .spp-qna__content {
    .p-w-r {
      .pr-qa-display:not(.pr-qa-display-desktop) {
        display: flex;
        flex-direction: column;
        .pr-qa-display-ask-question {
          order: 1;
          .pr-answer-form-open {
            margin: 0;
          }
        }
        .pr-qa-display-headline {
          order: 2;
        }
        .pr-qa-display-question {
          order: 3;
        }
        .pr-qa-display-show-more-questions {
          order: 4;
        }
      }
    }
  }
}

// Snippet
.review-snippet .p-w-r {
  .pr-snippet-stars-reco-reco {
    display: none;
  }
}

// Category snippet
body .product-brief .p-w-r {
  .pr-snippet {
    display: table;
    margin: 0 auto;
    .pr-category-snippet__total {
      display: none;
    }
    .pr-rating-stars {
      @include pr-star-svg(12px, 10px, 10px);
      display: inline-flex;
      @include breakpoint($medium-up) {
        @include pr-star-svg(22px, 17px, 17px);
      }
    }
  }
}

// POWER REVIEW CONTAINER SIGN IN PAGE
#power_review_container {
  float: left;
  width: 100%;
  padding: 0 10px 120px 10px;
  position: relative;
  z-index: 50;
  .p-w-r {
    font-family: $font--proxima-nova;
    input[type='text'],
    input[type='email'],
    input[type='password'],
    input[type='date'],
    input[type='search'],
    input[type='tel'],
    input[type='number'],
    input[type='time'],
    input[type='url'],
    textarea,
    select {
      @include input-styles;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    textarea {
      line-height: 1.5;
      font-size: 16px;
    }
    .pr-btn-default,
    a[role='button'],
    div[role='button'] {
      @include button;
      flex-direction: row;
      -webkit-appearance: none;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    a[role='button']:after {
      color: $color-white;
      margin-left: 4px !important;
      display: inline-block;
      width: 4px !important;
    }
    .pr-btn-default {
      background: transparent;
      color: $color-black;
      border: 2px solid $color-pink-hover;
      &:hover {
        background: $color-pink-2;
        color: $color-black;
      }
      &.pr-btn-fileinput,
      &.active {
        @include button--pink;
      }
      &.pr-btn-add-tag-submit {
        @include button;
        margin: 10px auto;
      }
      svg circle {
        fill: transparent;
      }
    }
    .tag-group .pr-label-control {
      &.checked {
        @include button--gold;
        min-width: 0;
        span {
          padding: 0;
        }
      }
    }
  }
  .pr-header-required {
    display: flex;
  }
  .pr-header-product-img {
    display: none;
  }
  .product-full {
    &__container {
      display: flex;
      flex-direction: column;
      @include breakpoint($medium-up) {
        flex-direction: row;
      }
    }
    &__image {
      width: 35%;
      margin-right: 5%;
      float: left;
      @include breakpoint($small-down) {
        width: 100%;
        text-align: center;
        margin-right: 0;
      }
      &--single:before {
        padding-top: 0;
      }
      img {
        margin-top: 65px;
        margin-bottom: 10px;
        float: left;
        padding-top: 55px;
        @include breakpoint($small-down) {
          width: 190px;
          float: none;
          padding-top: 0;
        }
      }
    }
    &__details {
      float: left;
      width: 60%;
      padding-top: 20px;
      @include breakpoint($small-down) {
        width: auto;
      }
      .product-full__name {
        @include title-large;
        color: $color-black;
      }
      .js-product-image-link {
        color: $color-white;
        text-decoration: none;
      }
    }
  }
  .p-w-r {
    .thank-you-page {
      .col-sm-12 {
        .pr-rating-number {
          border: none;
          span {
            float: left;
          }
        }
        span {
          float: none;
        }
      }
    }
    .pr-media-preview {
      .pr-loading-indicator {
        @include breakpoint(($small-down) (orientation portrait)) {
          width: 35px;
          height: 35px;
        }
      }
    }
    .pr-btn-danger {
      @include breakpoint(($small-down) (orientation portrait)) {
        padding: 2px 5px;
      }
    }
    fieldset {
      width: auto;
    }
    .pr-header {
      border-bottom: 0;
      @include breakpoint($small-down) {
        position: absolute;
        top: 10px;
        width: 100%;
      }
    }
    span {
      font-size: 14px;
      line-height: 1.5;
      color: $color-black;
      font-weight: normal;
      text-transform: uppercase;
      display: block;
      float: left;
    }
    .pr-cb-tag-container {
      span {
        float: none;
      }
    }
    .pr-label-control.checked {
      span {
        float: none;
        color: $color-white;
      }
    }
    .input-add-tag {
      .pr-btn-add-tag-submit {
        padding: 7px 7px;
        height: 32px;
      }
    }
    input,
    select {
      height: 32px !important;
      color: $color-black;
      font-size: 14px;
      letter-spacing: normal;
      padding: 5px 10px;
    }
    select {
      max-width: 100%;
      padding: 3px !important;
      -webkit-appearance: menulist;
    }
    .pr-war {
      max-width: 1024px;
      .pr-submit {
        .pr-accessible-btn {
          margin: 25px 0 50px 0;
        }
        .pr-subscript {
          line-height: 16px;
          font-size: 14px;
          text-transform: none;
        }
        .pr-subscript a {
          text-decoration: underline;
          font-size: 14px;
        }
      }
    }
    .pr-header-title {
      margin: 0;
      color: $color-black;
      font-size: 18px;
      line-height: normal;
      display: block;
      font-weight: bold;
      border-bottom: 2px solid $color-black;
      padding-bottom: 15px;
    }
    #pr-war-form {
      .form-group {
        .pr-control-label {
          span {
            display: inline;
            float: none;
            font-weight: bold;
          }
          @include breakpoint($small-down) {
            width: 100% !important;
          }
        }
        .pr-helper-text {
          text-transform: none;
        }
        input::placeholder {
          font-size: 14px;
          line-height: 1.5;
        }
        #pr-bottomline {
          .pr-control-label {
            width: 100% !important;
          }
          .btn-group-2-count {
            .pr-btn {
              &.active {
                @include button--pink;
              }
            }
            @include breakpoint($small-down) {
              width: 100%;
            }
          }
          .pr-helper-text {
            display: none;
          }
        }
        .btn-group {
          .pr-btn {
            padding: 8px 10px;
            &.active {
              background: $color-black;
            }
          }
        }
        .pr-btn-danger {
          background-color: $color-black;
          border-color: $color-black;
          &:hover {
            background-color: $color-pink;
            border-color: $color-pink;
          }
        }
        &.pr-has-error {
          input {
            &.form-control {
              border-color: $color-pink-accent;
            }
          }
          .pr-control-label {
            span {
              color: $color-pink-accent;
            }
          }
        }
        &.pr-rating-form-group {
          @include pr-star-bg(20px, 20px);
          label {
            color: $color-black;
            float: none !important;
            position: relative;
            span {
              color: $color-black;
            }
          }
        }
      }
      .pr-email_collection-form-group {
        display: none;
      }
      .pr-skinconcerns-form-group,
      .pr-skinconcern-form-group,
      .pr-productbenefits-form-group {
        .pr-control-label {
          float: left !important;
          margin-right: 20px;
        }
      }
    }
  }
  #pwr_signin {
    @include breakpoint($small-down) {
      padding-top: 0;
    }
    overflow: auto;
  }
  .pr_prod_img {
    width: 50%;
    float: left;
    @include breakpoint($small-down) {
      width: 100%;
      float: none;
      text-align: center;
    }
  }
  .email_check {
    width: 45%;
    float: left;
    h3 {
      margin-top: 16px;
      margin-bottom: 20px;
      font-size: 16px;
      font-family: $font--proxima-nova;
      color: $color-black;
    }
    h2 {
      font-size: 16px;
      color: $color-black;
      font-family: $font--proxima-nova;
      font-weight: 700;
      text-transform: uppercase;
    }
    .pr_email {
      margin-bottom: 20px;
      padding: 10px;
    }
    .error {
      margin-bottom: 15px;
      color: $color-pink-accent;
    }
    form {
      width: 100%;
      margin: 0;
    }
    @include breakpoint($small-down) {
      width: 100%;
      float: none;
    }
  }
  .full_sign_in {
    float: left;
    width: 45%;
    h2 {
      font-size: 21px;
      color: $color-black;
      &:nth-child(2) {
        font-size: 17px;
        margin-bottom: 20px;
      }
    }
    .pr_row_email {
      margin-bottom: 15px;
    }
    .pr-row_password {
      .form-text {
        float: left;
        margin-right: 10px;
        @include breakpoint($small-down) {
          float: none;
        }
        @include breakpoint(($small-down) (orientation landscape)) {
          float: left;
        }
      }
      div {
        padding-top: 4px;
      }
    }
    .pr_row_submit {
      clear: both;
      padding-top: 15px;
    }
    .error {
      margin-bottom: 15px;
      color: $color-pink-accent;
    }
    @include breakpoint($small-down) {
      width: 100%;
      float: none;
    }
  }
}
.site-footer {
  clear: both;
}
#pr-write {
  .p-w-r {
    .pr-table-cell:nth-child(2) {
      @include breakpoint($small-down) {
        display: block;
      }
      h3 {
        @include breakpoint($small-down) {
          text-align: center;
        }
      }
    }
    .thank-you-page {
      text-transform: none;
      .title {
        margin-top: 0;
      }
      .header {
        margin-top: 0;
        h5 {
          a {
            text-decoration: underline;
          }
        }
      }
      .review-preview {
        .pr-text {
          font-size: 14px;
          span {
            text-transform: none;
            display: inline-block;
          }
        }
        #war-ty-cons,
        #war-ty-best-uses {
          display: none;
        }
        .pr-rating-stars {
          position: relative;
          @include pr-star-bg(20px, 20px);
        }
      }
    }
    .form-group {
      .pr-btn-default {
        span:after {
          color: $color-white;
        }
      }
    }
    .pr-footer {
      .pr-accessible-btn {
        font-size: 14px;
        font-weight: normal;
        padding: 9px 15px;
      }
      a {
        font-size: 12px;
        text-decoration: none;
      }
    }
    #pr-alert {
      display: block;
      h4,
      p {
        line-height: 20px;
        text-transform: none;
      }
    }
  }
}
.write-review {
  &__container {
    margin: 0 auto;
    background: linear-gradient($color-pink-2, $color-pink-2 200px, $color-white 200px);
    position: relative;
    max-width: $max-width;
    padding: 0 20px 25px;
    @include breakpoint($medium-up) {
      margin: 0 auto 100px;
      padding: 20px 20px 0;
      display: flex;
      background: $color-pink-2;
      flex-direction: row;
    }
  }
  &__image {
    @include breakpoint($medium-up) {
      float: left;
      width: 50%;
      text-align: center;
    }
  }
  &__details {
    @include breakpoint($medium-up) {
      float: right;
      width: 45%;
      clear: right;
    }
  }
  &__name {
    font-size: 20px;
    text-align: center;
    line-height: 23px;
    @include breakpoint($medium-up) {
      font-size: 40px;
      line-height: 40px;
    }
  }
  &__header {
    display: none;
  }
}
