///
/// @file global/_mixins.scss
///
/// \brief Mixins are helpful snippets that let us not repeat ourselves
///
/// Mixins output css and style rules.
///

/// https://css-tricks.com/snippets/sass/str-replace-function/
/// Replace `$search` with `$replace` in `$string`
/// @author Hugo Giraudel
/// @param {String} $string - Initial string
/// @param {String} $search - Substring to replace
/// @param {String} $replace ('') - New value
/// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

///
/// Fluid Images for responsive designs
///
@mixin fluid-image {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%;
}

///
/// Font Smoothing
/// Primarily used to improve rendering of light text on dark background
///
@mixin font-smoothing($value: true) {
  @if ($value == true) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

///
/// Style input placeholders
///
@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
    opacity: 1 !important;
  } //opacity needed to override FF user agent
  &::-moz-placeholder {
    @content;
    opacity: 1 !important;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

///
/// Style input checkbox and input radio element
///
@mixin input-styled-check($icon, $suffix: 'checked', $padding: 5px) {
  // hide the actual element
  // TODO: Required by mantle, may be able to remove/refactor later
  /*
  position: absolute;
  left: -9999px;
  ~ label,
  ~ .label {
    background: url(#{$base-theme-path}/svg-icons/src/{$icon}.svg) no-repeat 50%;
    &::before {
      margin-right: $padding;
      margin-top: -2px;
    }
  }
  &:checked {
    ~ label,
    ~ .label {
      background: url(#{$base-theme-path}/svg-icons/src/{$icon}.svg) no-repeat 50%;
    }
  }

  // Add focus styles
  &:focus + label::before {
    outline: $color-focus auto 5px;
  }
  */
}

///
/// Center Element on any Axis
/// https://css-tricks.com/snippets/sass/centering-mixin/them if it's important.
// NOTE: assumes parent element has position: relative;
@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    @include transform(translate(-50%, -50%));
    top: 50%;
    left: 50%;
  } @else if ($horizontal) {
    @include transform(translate(-50%, 0));
    left: 50%;
  } @else if ($vertical) {
    @include transform(translate(0, -50%));
    top: 50%;
  }
}

@mixin destroy-centerer() {
  @include transform(none);
  position: static;
  top: auto;
  left: auto;
}

///
/// Triangles - creates right triangles with border-clipping tricks.
///
/// Adapted from this: http://codepen.io/vieron/pen/xuHDA
///
/// Usage:
/// @include triangle(size, color, direction)
/// @include bordered-triangle(size, color, direction, border-color, [border-width], [cut])
///
/// size: The length of the hypotenuse of the triangle
/// color: The fill color of the triangle
/// direction: Which way the arrow points
///
/// border-color: The color of the border around the triangle
/// border-width: The width of the border around the triangle
/// cut: Removes the border along the hypotenuse
///
@mixin triangle($size, $color, $direction) {
  height: 0;
  width: 0;
  border-color: transparent;
  border-style: solid;
  border-width: $size / 2;
  @if $direction == up {
    border-bottom-color: $color;
  } @else if $direction == right {
    border-left-color: $color;
  } @else if $direction == down {
    border-top-color: $color;
  } @else if $direction == left {
    border-right-color: $color;
  }
}

@mixin bordered-triangle($size, $color, $direction, $border-color, $border-width: 1px, $cut: true) {
  $back-size: $size + ($border-width * 2);
  position: relative;
  min-width: $size;
  min-height: $size;
  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
  }
  &:after {
    @include triangle($size, $color, $direction);
  }
  &:before {
    @include triangle($back-size, $border-color, $direction);
    $mid-border-width: ($border-width / 2);
    $border-width-and-mid: $border-width + $mid-border-width;
    @if ($cut == true) {
      $mid-border-width: 0;
      $border-width-and-mid: $border-width * 2;
    }
    @if ($direction == up) or ($direction == down) {
      margin-left: -$border-width;
      @if ($direction == down) {
        margin-top: -$mid-border-width;
      } @else {
        margin-top: -$border-width-and-mid;
      }
    } @else if ($direction == right) or ($direction == left) {
      margin-top: -$border-width;
      @if ($direction == right) {
        margin-left: -$mid-border-width;
      } @else {
        margin-left: -$border-width-and-mid;
      }
    }
  }
}

@mixin aspect-ratio($width, $height, $content-selector: '.content') {
  $ratio: $height / $width;
  @include aspect-ratio-explicit($ratio, $content-selector);
}

@mixin aspect-ratio-explicit($ratio, $content-selector: '.content') {
  position: relative;
  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: $ratio * 100%;
  }
  > #{$content-selector} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin tracking($tracking) {
  letter-spacing: ($tracking / 1000) * 1em;
}

@mixin normal-list {
  ul {
    list-style: outside disc;
    padding-left: 20px;
    margin-left: 0;
    margin-bottom: 15px;
  }
  ol {
    list-style: outside decimal;
    padding-left: 20px;
    margin-left: 0;
    margin-bottom: 15px;
  }
  ul ul,
  ol ul {
    list-style-type: circle;
    margin-left: 15px;
  }
  ol ol,
  ul ol {
    list-style-type: lower-latin;
    margin-left: 15px;
  }
  li {
    text-align: left;
    margin-top: 10px;
  }
  li:first-child {
    margin-top: 0;
  }
}

@mixin maxWidths {
  .max-width-30 {
    max-width: 30%;
  }
  .max-width-35 {
    max-width: 35%;
  }
  .max-width-40 {
    max-width: 40%;
  }
  .max-width-50 {
    max-width: 50%;
  }
  .max-width-60 {
    max-width: 60%;
  }
  .max-width-70 {
    max-width: 70%;
  }
  .max-width-80 {
    max-width: 80%;
  }
  .max-width-90 {
    max-width: 90%;
  }
  .max-width-100 {
    max-width: 100%;
  }
  .max-width-30-mobile {
    @include breakpoint($small-only) {
      max-width: 30%;
    }
  }
  .max-width-35-mobile {
    @include breakpoint($small-only) {
      max-width: 35%;
    }
  }
  .max-width-40-mobile {
    @include breakpoint($small-only) {
      max-width: 40%;
    }
  }
  .max-width-50-mobile {
    @include breakpoint($small-only) {
      max-width: 50%;
    }
  }
  .max-width-60-mobile {
    @include breakpoint($small-only) {
      max-width: 60%;
    }
  }
  .max-width-70-mobile {
    @include breakpoint($small-only) {
      max-width: 70%;
    }
  }
  .max-width-80-mobile {
    @include breakpoint($small-only) {
      max-width: 80%;
    }
  }
  .max-width-90-mobile {
    @include breakpoint($small-only) {
      max-width: 90%;
    }
  }
  .max-width-100-mobile {
    @include breakpoint($small-only) {
      max-width: 100%;
    }
  }
}

@mixin borderGradient {
  position: absolute;
  left: 0;
  background: url(#{$base-theme-path}img/gradient-border.png) repeat-x;
  height: 7px;
  width: 100%;
}

@mixin heartBorder {
  position: absolute;
  top: 100;
  left: 0;
  width: 100%;
  color: $color-gold;
  &:before {
    content: ' ';
    position: absolute;
    width: 100%;
    min-width: 50px;
    height: 3px;
    left: 50%;
    top: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    background: $color-gold;
  }
  .icon {
    position: relative;
    z-index: 2;
    padding: 0 4px;
    display: inline-block;
    fill: $color-gold;
    background: $color-white;
    font-size: 11px;
  }
}

// Create through http://triangle.designyourcode.io/
@mixin rightCaret {
  &:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4.5px 0 4.5px 7.8px;
    border-color: transparent transparent transparent $color-black;
  }
}

@mixin contentBlockImageHeight {
  .content-block__picture {
    position: relative;
    z-index: 2;
    height: 100%;
    img,
    video {
      display: block;
      object-fit: cover;
    }
  }
  .content-block__content {
    height: 100%;
  }
  .content-block__content-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    top: 0;
    left: 0;
  }
}

@mixin contentBlockTextHeight {
  .content-block__picture {
    position: absolute;
    height: 100%;
    width: 100%;
    picture {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    img,
    video {
      width: auto;
      min-width: 100%;
      min-height: 100%;
    }
  }
  .mantle-placement--active {
    width: 100%;
    transform: none !important;
    position: static;
  }
  .content-block__content-wrapper {
    position: relative;
    z-index: 2;
    height: 100%;
  }
}

@mixin gradientBorderBottom {
  padding-bottom: 11px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    background: url(#{$base-theme-path}img/content-border-bottom.png) no-repeat;
    background-position: 0 0;
    background-size: 100% auto;
    height: 3px;
    width: 100%;
  }
}

@mixin goldbar-header {
  @include body-medium;
  position: relative;
  display: flex;
  justify-content: center;
  overflow: hidden;
  margin: 7px 0;
  @include breakpoint($medium-up) {
    margin: 10px 0 13px;
  }
  > * {
    display: inline-block;
    margin: 0;
    background: $color-white;
    padding: 0 15px;
    @include breakpoint($medium-up) {
      padding: 0 25px;
    }
  }
  &:before {
    transform: translate(-50%, -50%);
    position: absolute;
    left: 50%;
    top: 50%;
    content: ' ';
    background: url(#{$base-theme-path}/img/gradient-border.png) repeat-x;
    background-size: cover;
    height: 4px;
    width: 100%;
    max-width: $max-width;
    z-index: -1;
    @include breakpoint($medium-up) {
      height: 7px;
    }
  }
}

// slick carousel shared styles
// not sure if this should be put into the styleguide or not. mixin for now.
@mixin toofaced-carousel-arrows {
  > .slick-arrow {
    @include aspect-ratio(30px, 50px);
    transition: opacity 1s;
    position: absolute;
    background: $color-carousel-arrow-bg-solid
      url(#{$base-theme-path}/img/icons/svg/arrow--right.svg) no-repeat;
    background-size: cover;
    width: 45px;
    height: auto;
  }
  > .slick-next {
    right: 0;
  }
  > .slick-prev {
    left: 0;
    transform: rotate(180deg);
  }
}

@mixin toofaced-carousel-arrows--small {
  > .slick-arrow {
    @include aspect-ratio(15px, 25px);
    transition: opacity 1s;
    position: absolute;
    background: url(#{$base-theme-path}svg-symbols/src/arrow--right.svg) no-repeat;
    background-size: cover;
    background-color: none;
    width: 10px;
    height: auto;
    &:before {
      font-size: 16px;
    }
  }
  > .slick-next {
    margin-top: -10px; // this is dumb - there are compass styles colliding here
    right: 0;
  }
  > .slick-prev {
    left: 0;
    margin-top: -10px; // this is dumb - there are compass styles colliding here
    transform: rotate(180deg);
  }
}

@mixin input-styles {
  @include appearance(none);
  @include input-placeholder {
    color: $color-black;
    font-size: 11px;
    letter-spacing: 0.05em;
    line-height: 20px;
    text-transform: uppercase;
    font-family: $font--proxima-nova;
  }
  @include swap_direction(padding, 1rem 40px 1rem 15px);
  border: 1px solid $color-pink;
  border-radius: 0;
  color: $color-black;
  font-size: $base-font-size;
  font-family: $font--proxima-nova;
  height: 44px;
  line-height: 1;
  text-transform: none;
  transition: border-color 0.3s, color 0.3s;
  @include breakpoint($small-down) {
    width: 100%;
  }
  &:focus,
  &:active {
    @include input-placeholder {
      color: transparent;
    }
    outline: 0;
    border-color: $color-focus;
  }
  &.error {
    @include input-placeholder {
      color: $color-error;
    }
    border-color: $color-error;
    color: $color-error;
  }
}

// accordion title - converted from extend to mixin
@mixin accordion-title {
  background: url(#{$base-theme-path}svg-icons/src/plus.svg) no-repeat;
  @if $cr24 {
    @include header-small;
    padding-inline-start: $accordion-mobile-padding;
    background-position: right $accordion-mobile-padding center;
    background-size: 16px;
  } @else {
    font-size: 13px;
    @include breakpoint($medium-up) {
      font-size: 14px;
    }
  }
  position: relative;
  cursor: pointer;
  text-transform: uppercase;
  line-height: 1.9;
  &:after {
    @include centerer(false, true);
    right: 0;
    font-size: 14px;
  }
  &.active {
    background: url(#{$base-theme-path}svg-icons/src/minus.svg) no-repeat;
    @if $cr24 {
      padding-inline-start: $accordion-mobile-padding;
      background-position: right $accordion-mobile-padding center;
      background-size: 16px;
    }
  }
}

///
/// Font mixins
///
/// These are the generic typography styles across the site.

@mixin font--bodoni-bold {
  font-family: $font--bauer-bodoni;
}

@mixin font--bodoni-bold-italic {
  font-family: $font--bauer-bodoni;
  font-style: italic;
}

@mixin font--proxima-nova-light {
  font-family: $font--proxima-nova;
  font-weight: 100;
}

@mixin font--proxima-nova {
  font-family: $font--proxima-nova;
  font-weight: normal;
}

@mixin font--proxima-nova-bold {
  font-family: $font--proxima-nova;
  font-weight: 700;
}

@mixin font--proxima-nova-semibold {
  font-family: $font--proxima-nova;
  font-weight: 600;
}

@mixin font--madina-alt {
  font-family: $font--madina-alt;
}

@mixin font--gotcha-regular {
  font-family: $font--gotcha-regular;
  font-weight: normal;
}

///
/// Typography Styles
///

@mixin header-large {
  @include font--bodoni-bold;
  font-size: 42px;
  text-transform: uppercase;
  @include breakpoint($landscape-up) {
    font-size: 64px;
  }
}

@mixin header-medium {
  @include font--bodoni-bold;
  font-size: 32px;
  text-transform: uppercase;
  @include breakpoint($landscape-up) {
    font-size: 48px;
  }
}

@mixin header-small {
  @include font--bodoni-bold;
  font-size: 18px;
  text-transform: uppercase;
  @include breakpoint($landscape-up) {
    font-size: 24px;
  }
}

@mixin title-large {
  @include font--proxima-nova-bold;
  font-size: 18px;
  @include breakpoint($landscape-up) {
    font-size: 24px;
  }
}

@mixin title-small {
  @include font--proxima-nova-bold;
  font-size: 14px;
  @include breakpoint($landscape-up) {
    font-size: 16px;
  }
}

@mixin eyebrow-large {
  @include font--proxima-nova-bold;
  font-size: 14px;
  text-transform: uppercase;
  @include breakpoint($landscape-up) {
    font-size: 16px;
  }
}

@mixin eyebrow-small {
  @include font--proxima-nova-bold;
  font-size: 12px;
  text-transform: uppercase;
  @include breakpoint($landscape-up) {
    font-size: 14px;
  }
}

@mixin body-large {
  @include font--proxima-nova;
  font-size: 18px;
  @include breakpoint($landscape-up) {
    font-size: 24px;
  }
}

@mixin body-medium {
  @include font--proxima-nova;
  font-size: 16px;
  @include breakpoint($landscape-up) {
    font-size: 18px;
  }
}

@mixin body-small {
  @include font--proxima-nova;
  font-size: 14px;
  @include breakpoint($landscape-up) {
    font-size: 16px;
  }
}

@mixin body-x-small {
  @include font--proxima-nova;
  font-size: 12px;
  @include breakpoint($landscape-up) {
    font-size: 14px;
  }
}

@mixin fine-print {
  @include font--proxima-nova;
  font-size: 10px;
  @include breakpoint($landscape-up) {
    font-size: 12px;
  }
}

@mixin breadcrumb-font {
  @include fine-print;
}

@mixin add-ellipsis($max-height) {
  // For browsers that don't support ellipsis yet
  margin-right: -1em;
  padding-right: 1em;
  position: relative;
  height: $max-height;
  overflow: hidden;
  &::before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
  }
  &::after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 3em;
    margin-top: 0.4em;
    background: $white;
  }
}

@mixin breadcrumb-back-arrow-link {
  display: inline;
  &:before {
    // Hide original unlinked arrow
    display: none;
  }
  // Turn link into an arrow
  a {
    display: inline-block;
    padding: 5px 5px 5px 0;
    background: url(#{$base-theme-path}svg-icons/src/arrow--down.svg) no-repeat;
    &:before {
      font-size: 7px;
      transform: rotate(90deg) translateY(5px);
    }
  }
}

// todo move to mantle.scss
@mixin grid-element($col-start, $col-end, $row-start, $row-end) {
  $col-span: $col-end + 1 - $col-start;
  $row-span: $row-end + 1 - $row-start;
  -ms-grid-column: $col-start;
  -ms-grid-column-span: $col-span;
  -ms-grid-row: $row-start;
  -ms-grid-row-span: $row-span;
  grid-column: #{$col-start}/ span #{$col-span};
  grid-row: #{$row-start}/ span #{$row-span};
}

@mixin grid-element-start($row-start, $col-start) {
  @include grid-column-start($col-start);
  @include grid-row-start($row-start);
}

@mixin grid-column-start($col-start) {
  -ms-grid-column: $col-start;
  grid-column-start: #{$col-start};
}

@mixin grid-row-start($row-start) {
  -ms-grid-row: $row-start;
  grid-row-start: #{$row-start};
}

@mixin grid-column-span($col-span) {
  -ms-grid-column-span: $col-span;
  grid-column-end: span #{$col-span};
}

@mixin grid-row-span($row-span) {
  -ms-grid-row-span: $row-span;
  grid-row-end: span #{$row-span};
}

@mixin disable-lazyload-images {
  .lazyload {
    display: none;
  }
}

@mixin enable-lazyload-images($display: block) {
  .lazyload {
    display: $display;
  }
}

@mixin panel-border {
  background-image: url(/sites/toofaced/themes/toofaced_base/img/gradient-border.png);
  background-repeat: no-repeat;
  background-position: center top;
}

@mixin mobile-sticky-checkout-button {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1002;
  padding: 15px;
  background: $color-white;
  box-shadow: 0 -1px 6px $color-light-grey;
  @include breakpoint($landscape-up) {
    position: inherit;
    background: none;
    box-shadow: none;
    padding: 0;
  }
  .continue-checkout {
    line-height: 3.5rem;
    height: 3.5rem;
    font-size: 16px;
    padding: 0 10px;
    font-family: $font--text;
  }
  .continue-checkout-margin-top {
    margin-top: 10px;
    @include breakpoint($landscape-up) {
      margin-top: 20px;
    }
  }
  .paypal-checkout {
    margin-top: 10px;
    @include breakpoint($landscape-up) {
      margin-top: 20px;
    }
  }
}

@mixin page-header {
  font-family: $font--bauer-bodoni;
  font-size: 32.5px;
  text-transform: uppercase;
  @include breakpoint($landscape-up) {
    font-size: 45px;
  }
}

@mixin selectbox-icon {
  @include triangle(12px, $color-black, down);
  content: '';
  position: absolute;
  transition: transform 500ms;
  transform-origin: 50% 25%;
  margin-top: -3px;
  top: 50%;
  right: 10px;
}

@mixin madina-title {
  @include font--madina-alt;
  font-weight: 400;
  line-height: 0.7;
  vertical-align: top;
  text-transform: lowercase;
  font-size: 30px;
  @include breakpoint($medium-up) {
    text-transform: capitalize;
    font-size: 50px;
  }
}

@mixin inline-bullets {
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    text-align: left;
    margin-top: 10px;
    &:first-child {
      margin-top: 0;
    }
    &:before {
      content: '• ';
    }
  }
}

@mixin icon-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: center;
  @include breakpoint($medium-up) {
    text-align: left;
  }
  li {
    margin: 0;
    padding: 0;
    display: inline-block;
    @include breakpoint($medium-up) {
      margin-right: 5px;
    }
    img {
      max-width: 60px;
    }
  }
}

@mixin border-underline {
  @include font--proxima-nova;
  position: relative;
  line-height: 1em;
  display: inline-block;
  margin-bottom: 1em;
  font-size: mobilePx(32px);
  @include breakpoint($medium-up) {
    font-size: 22px;
  }
  &:before {
    // having height at 1px was giving weird subpixel errors.
    // double height and scale down seemed to fix.
    @include transform(scaleY(0.5) translate(-50%, -50%));
    position: absolute;
    bottom: -5px;
    left: 50%;
    content: '';
    background: $color-black;
    height: 2px;
    width: 100%;
  }
}

@mixin details-section-header {
  @include eyebrow-large;
  position: relative;
  display: inline-block;
  margin-bottom: 1em;
}

@mixin editorial-lists {
  @include normal-list;
  ul.inline-bullets {
    @include inline-bullets;
  }
  ul.icon-list {
    @include icon-list;
  }
}

@mixin tips-header {
  @include font--bodoni-bold;
  font-size: mobilePx(50px);
  position: relative;
  margin-bottom: 30px;
  line-height: 1em;
  text-align: center;
  @include breakpoint($medium-up) {
    font-size: 50px;
  }
  &:before {
    @include transform(translate(-50%, -50%));
    position: absolute;
    bottom: -20px;
    left: 50%;
    content: '';
    background: $color-border-gold-brown;
    height: 3px;
    width: 200px;
    @include breakpoint($medium-up) {
      width: 270px;
    }
  }
}

/**
 * Basic ltr/rlt switcher.
 *
 * Handles the form of:
 *
 * If $ltr-rule has a left/right in it. This will be flipped for rtl.
 * If the $rtl-value is provided, it will replace the value used for the rtl
 * rule. Otherwise it will be the same as ltr.
 *
 * While this won't natively switch (top, right, bottom, left) declarations,
 * you can also just use the right-value switch as shown below:
 *
 * Examples:
 *
 * @include ltr-rule(padding-left, 10px)
 *
 *   [dir="ltr"] & { padding-left: 10px; }
 *   [dir="rtl"] & { padding-right: 10px; }
 *
 * @include ltr-rule(margin-left, 10px, 5px)
 *   [dir="ltr"] & { margin-left: 10px; }
 *   [dir="rtl"] & { margin-right: 5px; }
 *
 * @include ltr-rule(margin, 0 10px 0 20px, 0 20px 0 10px)
 *   [dir="ltr"] & { margin: 0 10px 0 20px; }
 *   [dir="rtl"] & { margin: 0 20px 0 10px; }
 *
 */
@mixin ltr-rule($ltr-rule, $value, $rtl-value: null) {
  // See if we have a left/right in ltr rule
  $left-index: str-index($ltr-rule, 'left');
  $right-index: str-index($ltr-rule, 'right');
  @if ($rtl-value == null) {
    $rtl-value: $value;
  }

  $rtl-rule: $ltr-rule;
  @if $left-index {
    $rtl-rule: str-replace($ltr-rule, 'left', 'right');
  }
  @if $right-index {
    $rtl-rule: str-replace($ltr-rule, 'right', 'left');
  }
  [dir='ltr'] & {
    #{$ltr-rule}: $value;
  }
  [dir='rtl'] & {
    #{$rtl-rule}: $rtl-value;
  }
}

@mixin swap_direction($type, $v) {
  @if length($v) == 4 {
    @if $text-direction == rtl {
      #{$type}: nth($v, 1) nth($v, 4) nth($v, 3) nth($v, 2);
    } @else {
      #{$type}: $v;
    }
  } @else {
    #{$type}: $v;
  }
}
/*
 * Extracted kit styles since checkout styles are duplicated.
 *
 * Should be included with a .cart-items__item/.cart-item context.
 *
 * This is in _mixins because it is used in account and checkout scss.
 */
@mixin kit-cart-item-styles {
  $item-cls: '.cart-items__item';

  // Kit Specific
  .cart-item__sub-items {
    margin-top: 10px;
  }

  &.kit-item,
  &.sample {
    #{$item-cls}--qty__label {
      padding: 0;
    }
  }

  .kit-subitem {
    // handle kit subitems
    .desc {
      margin-bottom: 0;
    }
    .shared-cart-item {
      &__info {
        .cart--item {
          &__product-name {
            font-size: 13px;
          }
          &__color {
            margin-top: 0;
          }
          &__swatch {
            @include ltr-rule(margin-right, 5px);
            @include ltr-rule(float, left, right);
            border-radius: 50%;
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }
}

// checkout related mixins
// secondary header/title style
@mixin checkout-secondary-heading {
  font-family: $main-font;
  font-size: get-rem(30px);
  font-weight: 300;
  letter-spacing: 0;
  line-height: get-line-height(30px, 32px);
}

// sub header/title style - medium version
@mixin checkout-sub-heading--medium {
  font-family: $main-font;
  font-size: get-rem(18px);
  font-weight: 300;
  letter-spacing: 0;
  line-height: get-line-height(18px, 24px);
}

// sub header/title style - small version - style 1
@mixin checkout-sub-heading--small {
  font-family: $main-font;
  font-size: get-rem(12px);
  line-height: get-line-height(12px, 18px);
}

// sub header/title style - small version - style 2
@mixin checkout-sub-heading--small-style2 {
  font-family: $bold-font-family;
  font-size: get-rem(12px);
  letter-spacing: 1.7px;
  line-height: get-line-height(12px, 20px);
  text-transform: uppercase;
}

// default styling for paragraphs/body text
@mixin checkout-body-text--medium {
  font-family: $main-font;
  font-size: get-rem(14px);
  letter-spacing: 0;
  line-height: get-line-height(14px, 24px);
}

// default styling for paragraphs/body text bold
@mixin checkout-body-text--bold {
  font-family: $bold-font-family;
  font-size: get-rem(14px);
  letter-spacing: 0;
  line-height: get-line-height(14px, 24px);
}

// default styling for smaller paragraphs/body text
@mixin checkout-body-text--small {
  font-family: $main-font;
  font-size: get-rem(12px);
  line-height: get-line-height(12px, 14px);
}
