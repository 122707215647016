// BUTTONS

// Button Structure
@mixin button--structure($include-responsive: false) {
  @if $include-responsive {
    min-width: auto;
    min-height: auto;
    padding: 0 5px;
    @include breakpoint($medium-up) {
      min-width: 250px;
      padding: 0 10px;
      min-height: $cta-button-min-height;
      .no-csspositionsticky & {
        height: $cta-button-min-height;
      }
    }
  } @else {
    min-width: 250px;
    padding: 0 10px;
    min-height: $cta-button-min-height;
    .no-csspositionsticky & {
      height: $cta-button-min-height;
    }
  }
  @include font--proxima-nova-bold;
  border: none;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s ease-out;
  font-size: 12px;
  cursor: pointer;
  span {
    position: relative;
    padding: 5px 0;
    line-height: 1.3;
  }
}

// Button Colors
@mixin button--black-color {
  background: $color-black;
  color: $color-white;
  &:hover,
  &:active {
    background: $color-black-hover;
    color: $color-white;
    text-decoration: none;
  }
}
@mixin button--black-pink-alt-color {
  background: $color-black;
  color: $color-pink-button-text;
  &:hover {
    background: $color-black-hover;
  }
}
@mixin button--pink-color {
  background: $color-pink;
  color: $color-black;
  &:hover {
    background: $color-pink-hover;
    color: $color-black;
  }
}
@mixin button--pink-accent-color {
  background: $color-pink-accent;
  color: $color-black;
  &:hover,
  &:active {
    background: $color-pink-accent-hover;
    color: $color-black;
  }
}
@mixin button--pink-accent-alt-color {
  background: $color-pink-accent;
  color: $color-white;
  &:hover,
  &:active {
    background: $color-pink-accent-hover;
    color: $color-white;
  }
}
@mixin button--gold-color {
  background: $color-gold;
  color: $color-black;
  &:hover {
    background: $color-gold-hover;
    color: $color-black;
  }
}
@mixin button--disabled-color {
  background: $color-disabled;
  box-shadow: none;
  color: $color-dark-gray;
  cursor: default;
  opacity: 0.5;
  &:hover {
    background: $color-disabled;
    color: $color-dark-gray;
  }
}

// Buttons
@mixin button--base {
  @include button--structure;
  @include button--black-color;
}

@mixin button--base-responsive {
  @include button--structure(true);
  @include button--black-color;
}

@mixin button--black {
  @include button--base;
}

@mixin button--black-responsive {
  @include button--base-responsive;
}

@mixin button--pink {
  @include button--structure;
  @include button--pink-color;
}

@mixin button--pink-responsive {
  @include button--structure(true);
  @include button--pink-color;
}

@mixin button--pink-accent {
  @include button--structure;
  @include button--pink-accent-color;
}

@mixin button--pink-accent-responsive {
  @include button--structure(true);
  @include button--pink-accent-alt-color;
}

@mixin button--gold {
  @include button--structure;
  @include button--gold-color;
}

@mixin button--gold-responsive {
  @include button--structure(true);
  @include button--gold-color;
}

@mixin button--disabled {
  @include button--structure;
  @include button--disabled-color;
}

@mixin button--disabled-responsive {
  @include button--structure(true);
  @include button--disabled-color;
}

@mixin button {
  @include button--base;
  &--base-responsive {
    @include button--base-responsive;
  }
  &--black {
    @include button--base;
  }
  &--black-responsive {
    @include button--black-responsive;
  }
  &--pink {
    @include button--pink;
  }
  &--pink-responsive {
    @include button--pink-responsive;
  }
  &--pink-accent {
    @include button--pink-accent;
  }
  &--pink-accent-responsive {
    @include button--pink-accent-responsive;
  }
  &--gold {
    @include button--gold;
  }
  &--gold-responsive {
    @include button--gold-responsive;
  }
  &--disabled {
    @include button--disabled;
  }
  &--disabled-responsive {
    @include button--disabled-responsive;
  }
}

// Alt Buttons
@mixin button--black-pink-alt {
  @include button--structure;
  @include button--black-pink-alt-color;
}

@mixin link-caret() {
  border-bottom: none;
  position: relative;
  margin-right: 1em; // spacing
  &:hover,
  &:active {
    border-bottom: none;
  }
  // absolute position
  span:after {
    transform: translate(100%, 0%);
    position: absolute;
    display: inline-block;
    line-height: 1em;
    content: ' ⊲';
    right: -0.3em;
    top: 0px;
  }
}

@mixin link-caret2() {
  position: relative;
  text-decoration: none;
  border-bottom: none;
  &:hover,
  &:active {
    text-decoration: none;
    border-bottom: none;
  }
  &:after {
    content: ' ⊲';
  }
}

@mixin link--base {
  @include font--proxima-nova-bold;
  text-transform: uppercase;
  font-size: 1rem;
  display: inline-block;
  transition: all 0.3s ease-out;
  color: $color-black;
  text-decoration: none;
  position: relative;
  &:hover,
  &:active {
    text-decoration: none;
  }
}

@mixin link--secondary($include-base: true) {
  @if $include-base {
    @include link--base;
  }
  @include font--proxima-nova;
  font-size: 18px;
}

@mixin link--inline-tout($include-base: true) {
  @if $include-base {
    @include link--base;
  }
  color: $color-pink-accent;
  font-size: 8px;
  &:hover,
  &:active {
    color: $color-black;
  }
  @include breakpoint($medium-up) {
    font-size: 14px;
  }
}

@mixin link {
  @include link--base;
  &--secondary {
    @include link--secondary(false);
  }
  &--inline-tout {
    @include link--inline-tout(false);
  }
  &--inline-tout--caret {
    @include link--inline-tout(false);
    @include link-caret;
  }
  &--caret {
    @include link-caret;
  }
}
